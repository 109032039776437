import { template as template_855fe3a3b6ea477b86868cf83da1e720 } from "@ember/template-compiler";
const WelcomeHeader = template_855fe3a3b6ea477b86868cf83da1e720(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
